import React from "react";
import withLayout from "../components/withLayout";
import { graphql } from "gatsby";
import styled from "styled-components";
import { HR, PageHeader, PageContainer, Divider } from "../components/common";
import StyledButton from "../components/StyledButton";
import { media } from "../css/theme";

const Box = styled.section`
  border: 1px solid white;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 8px;
  display: flex;
  flex-direction: column;
  h2 {
    margin-top: 0;
  }
  && p {
    margin: 16px 0px !important;
  }
  ${StyledButton} {
    width: 100%;
    margin-top: auto;
  }
`;

const FooterText = styled.div`
  p {
    text-align: center;
    font-size: 1.2rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet`
    flex-direction: row;
    flex-wrap: nowrap;
    ${Box} {
      padding: 24px;
    }
  `};
`;

const PricingCard = ({ title, desc, features, children }) => (
  <Box>
    <h2>{title}</h2>
    <HR />
    <h5>{desc}</h5>
    <div dangerouslySetInnerHTML={{ __html: features }} />
    {children}
  </Box>
);

// root page for the knowledge base canvis.app/guide
const Pricing = ({ content }) => (
  <PageContainer>
    <PageHeader>
      <h1>{content.title}</h1>
      <h4>{content.subtitle}</h4>
    </PageHeader>
    <Container>
      <PricingCard
        title={content.price_1_title}
        desc={content.price_1_desc}
        features={content.price_1_features}
      >
        <StyledButton>
          <a href="https://canvis.app">{content.price_1_button}</a>
        </StyledButton>
      </PricingCard>
      <PricingCard
        title={content.price_2_title}
        desc={content.price_2_desc}
        features={content.price_2_features}
      >
        <StyledButton color="red">
          <a href="mailto:info@canvis.app">{content.price_2_button}</a>
        </StyledButton>
      </PricingCard>
    </Container>
    <Divider>
      <FooterText dangerouslySetInnerHTML={{ __html: content.footer_text }} />
    </Divider>
  </PageContainer>
);

export default withLayout(Pricing);

export const query = graphql`
  query getPricing($Locale: String!) {
    content: allAirtable(
      filter: { table: { eq: "Pricing" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...static
    }
    layout: allAirtable(
      filter: { table: { eq: "Layout" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...layout
    }
  }
`;
